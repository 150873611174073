<template>
  <div>
    <!-- 对公转账弹窗 -->
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 101"
        @click.stop="hide"
      ></div>
      <div class="recharge-one  animated faster fadeIn">
        <div class="title-one space-between">
          <div class="row-center">
            <img src="../assets/glxt/17.png" alt="" />
            <span>请联系您的专属顾问</span>
          </div>
          <i class="el-icon-close pointer" @click="hide"></i>
        </div>
        <div class="direction">
          <img
            :src="kfObj.qrcode"
            class="sm-img-one"
            alt=""
          />
          <span class="wx-sys-text">请使用微信扫一扫添加</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      status: false,
      kfObj:null
    };
  },

  created() {
    this.kfObj=JSON.parse(localStorage.getItem('exclusiveObj'))
  },

  mounted() {},

  methods: {
    show() {
      this.status = true;
    },
    hide() {
      this.status = false;
    },
  },
};
</script>
<style lang='less' scoped>
.recharge-one {
  width: 28.75rem;
  height: 18.3125rem;
  background: #fefefe;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 102;
  padding: 1.375rem 1.9375rem 3.0625rem 1.875rem;
  .title-one {
    align-items: flex-start;
    div {
      color: #6a7182;
      font-size: 1rem;
      margin-top: 0.25rem;
      margin-bottom: 2.125rem;
      img {
        width: 1.5625rem;
        height: 1.5625rem;
        margin-right: 1.25rem;
      }
    }
  }
  .sm-img-one {
    width: 8.5rem;
    height: 8.5rem;
    margin-bottom: 0.6875rem;
  }
  .wx-sys-text {
    color: #999999;
    font-size: 0.875rem;
  }
}
</style>